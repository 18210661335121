<template>
    <b-card id="addAccountWrapper">
        <validation-observer ref="addAccount" #default="{ invalid }">
            <b-form @submit.prevent="saveAccount">
                <b-container>
                    <b-row class="justify-content-md-center">
                        <b-col cols="12" md="1">
                            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="flat-warning" class="btn-icon"
                                to="/myprofile/accounts" v-b-tooltip.hover title="Go Back">
                                <feather-icon icon="ArrowLeftCircleIcon" size="20" />
                            </b-button>
                        </b-col>
                        <b-col cols="12" md="11">
                            <h1>Account Creation</h1>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                        <b-col cols="12" md="10" class="ml-md-5">
                            <b-form-group label="Account *" label-for="d-name">
                                <validation-provider #default="{ errors }" name="Account name" rules="required">
                                    <b-form-input size="md" id="d-name" v-model="account"
                                        placeholder="Account Name or Account ID" autofocus
                                        :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger account_name_error">{{
                                        errors[0]
                                        }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <!-- input for the account's identifiant  -->
                    <!-- <b-row>
            <b-col md="10" class="ml-md-5" cols="12">
              <b-form-group label="Account Id" label-for="d-id">
                <validation-provider #default="{ errors }" name="Account ID">
                  <b-form-input size="md" id="d-id" v-model="account_id" placeholder="Account ID"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger account_id_error">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->
                    <!-- Event URL -->
                    <b-row>
                        <b-col md="10" class="ml-md-5" cols="12">
                            <validation-provider name="Account link" rules="url">
                                <b-form-group label="Account Link" label-for="event-url">
                                    <b-form-input size="md" id="event-url" v-model="account_link" type="url"
                                        placeholder="https://www.example.com" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <!--
          <b-row class="justify-content-md-center">
            <b-col md="6" class="ml-md-5" cols="12">
              <validation-provider name="Event URL" rules="url">
                <b-form-group label="Script Link" label-for="event-url">
                  <b-form-input size="sm"
                    id="event-url"
                    v-model="script_link"
                    type="url"
                    placeholder="https://www.example.com/example.js"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          -->
                    <b-row>
                        <b-col md="6">
                            <b-button type="submit" v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-success"
                                class="ml-md-5 w-50 d-block " :disabled="invalid">
                                Submit
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";

import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    VBTooltip,
    BButton,
    BContainer,
    BFormInvalidFeedback,
} from "bootstrap-vue";

import axios from "@axios";
import appsAxios from "@/libs/appsAxios";
import useAppConfig from '@core/app-config/useAppConfig';
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import appConfig from "@/store/app-config";

const { skin, skinClasses } = useAppConfig()
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BContainer,
        BFormInput,
        BFormGroup,
        BFormFile,
        BForm,
        VBTooltip,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BFormInvalidFeedback,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            account_name: null,
            account_id: null,
            account_link: null,
            account: null,
            server: 1,
            token: null,
            apps: [],
            // current_theme : 
            data: {},
            // validation rules
            required,
            regex,
            url,
        };
    },
    created() { },
    mounted() {
        this.server = JSON.parse(localStorage.getItem("userAccess")).server;
        this.token = JSON.parse(localStorage.getItem("userAccess")).token;

    },
    methods: {
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        async saveAccount() {
            appsAxios.get(`https://sc1.clicklab.app/api/developers/${this.account}?fullDetail=true`)
            .then((response) => {
                const data = response.data;
                this.account_name = data.apps[0].developer.devId
                this.account_id = data.apps[0].developerId
                return data;
            })
            .then((data) => {
                this.apps = [];
                data.apps.forEach((app) => {
                    this.apps.push(app);
                });
            }).then(() => {
                axios.post("/store-account", {
                    account_name: this.account_name,
                    account_link: this.account_link,
                    account_id: this.account_id,
                    user_token: JSON.parse(localStorage.getItem("accessToken")),
                    apps: this.apps
                }).then(({ data }) => {
                    if (data.status === 'success') {
                        this.showToast(
                            "success",
                            "CheckCircleIcon",
                            "Success",
                            data.message
                        );
                        router.push({ name: "accounts" });
                    } else {
                        this.showToast(
                            "warning",
                            "AlertOctagonIcon",
                            "Warning",
                            data.message
                        );
                    }
                }).catch((error) => {
                    this.showToast(
                        "danger",
                        "AlertOctagonIcon",
                        "Error",
                        "somthing Wrong !"
                    );
                });
            })
            .catch((error) => {
                console.error(error);
                this.showToast(
                    "danger",
                    "AlertOctagonIcon",
                    "Error",
                    "There's no application associated to this account!"
                );
            });

            // await axios
            //   .post("/store-account", {
            //     account_name: this.account_name,
            //     account_link: this.account_link,
            //     account_id: this.account_id,
            //     user_token: JSON.parse(localStorage.getItem("accessToken")),
            //   })
            //   .then(({ data }) => {
            //     if (data.status == "Unauthorized") {
            //       removeLocalStorageItems();
            //       router.push({ name: "auth-login" });
            //     }
            //     if (data.status == "success") {
            //       this.showToast("success", "SaveIcon", "Done", data.message);
            //       // this.$refs.resetButton.click();
            //       router.push({ name: "accounts" });
            //     } else if (data.status == "failed") {
            //       if (data.errors) {
            //         document.querySelector(".account_name_error").textContent =
            //           data.errors.account_name[0];
            //       }
            //       this.showToast(
            //         "danger",
            //         "AlertTriangleIcon",
            //         "Error",
            //         data.message
            //       );
            //     }
            //   })
            //   .catch((error) => {
            //     this.showToast(
            //       "danger",
            //       "AlertTriangleIcon",
            //       "Error",
            //       "Something went wrong!"
            //     );
            //   });
        },

        // getSelectedAccount() {
        //   axios
        //     .get(`/get-account/${this.$route.params.id}`)
        //     .then(({ data }) => {
        //       if (data.status == "Unauthorized") {
        //         router.push({ name: "auth-login" });
        //       }

        //       axios
        //         .get(
        //           `https://sc${this.server}.clicklab.app/api/developers/${
        //             isNaN(data.account.account_id)
        //               ? data.account.account_name
        //               : data.account.account_id
        //           }?fullDetail=true&token=${this.token}&from=am`
        //         )
        //         .then((response) => {
        //           const { status } = response.data;
        //           if (status === "Unauthorized") {
        //             removeLocalStorageItems();
        //             this.$root.$emit("bv::show::modal", "modal-session-app");
        //             return;
        //           }
        //           const data = response.data;
        //           return data;
        //         })
        //         .then((data) => {
        //           this.apps = [];
        //           data.apps.forEach((app) => {
        //             this.apps.push(app);
        //           });
        //         })
        //         .catch((error) => {
        //           this.showToast(
        //             "danger",
        //             "AlertOctagonIcon",
        //             "Error",
        //             "There's no application associated to this account!"
        //           );

        //           document.getElementById("apps-content").innerHTML =
        //             "There's no application associated to this account!";
        //         });
        //     })
        //     .catch((error) => console.error(error));
        // },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";


#addAccountWrapper {
    width: 60% !important;
    display: block;
    margin-inline: auto;
    margin-top: 3rem;
    box-shadow: 8px 6px 7px rgba(0, 0, 0, 0.2) !important;
}

@media (max-width: 560px) {
    #addAccountWrapper {
        width: 100% !important;

    }
}
</style>
